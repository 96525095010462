html, body {
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  min-height: 100vh;
}
#root {
  background-color: #fafafa;
  color: #424242;
  min-height: 100vh;
  height: 100vh;
}
main {
  flex: 1;
}
header, main, footer {
  display: block;
}
footer {
  align-self: flex-end;
}
.janus-dir-account-list-cloud-icon {
  display: block;
  height: 1rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  width: 2rem;
  height: 2rem;
}
.janus-dir-account-list-table th,
.janus-dir-account-list-table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}
.janus-dir-account-list-table th:nth-child(1) {
  width: 3rem;
}
.janus-dir-account-list-table th:nth-child(2) {
  width: auto;
}
.janus-dir-account-list-table th:nth-child(3) {
  width: auto;
}
.janus-dir-account-list-table td:nth-child(3) {
  font-family: "Roboto Mono", monospace;
}
.janus-dir-account-list-table th:nth-child(4) {
  width: auto;
  width: 10rem;
}
.janus-dir-account-list-table td:nth-child(4), .janus-dir-account-list-table th:nth-child(4) {
  text-align: left;
}
.janus-dir-account-list-table th:nth-child(5) {
  width: auto;
}
.janus-dir-account-list-table td:nth-child(5) .particles-collection {
  flex-wrap: nowrap;
  width: 100%;
  align-items: center;
}
.janus-dir-account-list-table td:nth-child(5)  {
  white-space: normal;
}
.janus-dir-account-list-cloud-icon--aws {
  background-image: url('./icons/icon-aws.svg');
}
.janus-dir-account-list-cloud-icon--azure {
  background-image: url('./icons/icon-azure.svg');
}
.janus-dir-account-list-cloud-icon--gcp {
  background-image: url('./icons/icon-google.svg');
}

.particles-modal__body > .particles-button-group {
  width: 100%;
  display: flex;
}
.particles-modal__body > .particles-button-group > * {
  flex: 1;
}
.particles-modal__footer {
  justify-content: flex-start;
}
.placeholder {
  height: 400px;
  text-align: center;
  font-size: 2rem;
  font-weight: 300;
  color: #666666;
}
.placeholder h1 {
  display: block;
  margin: 100px 32px 0;
  padding: 0;
  text-align: center;
  font-weight: 100;
  color: black;
}
.placeholder img {
  width: 300px;
  margin: 32px auto;
  display: block;
}

pre {
  background: #eaeaea;
  margin: 0;
  padding: 0;
}
pre code {
  font-family: "Roboto Mono", monospace;
  font-size: 0.75rem;
  line-height: 1;
  padding: 0.5rem;
}
[name="temporaryCredentials"] {
  font-family: "Roboto Mono", monospace;
  font-size: 0.75rem;
  white-space: pre;
}
.temporary-credentials-type-selector {
  margin-bottom: 1rem;
}
.loading-block {
  display: flex;
  height: 15rem;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
  font-size: 1.25rem;
}
.loading-block > i {
  margin-bottom: 1rem;
  font-size: 3rem;
  color: #666666;
}
.trust-relationship pre {
  width: 300px;
  overflow-x: auto;
  cursor: pointer;
}
.gcloud-cli-command pre, .azure-cli-command pre {
  width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.particles-button ~ pre {
  cursor: text;
}
.particles-modal .particles-page__main-body-section {
  margin-left: -1rem;
  margin-right: -1rem;
}
.particles-modal .particles-page__main-body-section:first-child {
  margin-top: 0;
  border-top: 1px solid #e7e7e7;
}
.particles-modal .particles-page__main-body-section + .particles-alertbar {
  margin-top: 1rem;
}
.particles-modal__footer {
  flex-wrap: wrap;
}
.particles-modal__footer > .particles-alertbar {
  width: 100%;
  flex: 1 0 100%;
}
.particles-modal__footer > .particles-alertbar + .particles-collection {
  width: 100%;
  flex: 1 0 100%;
  margin-top: 1rem;
}
.particles-button .collapse-icon {
  margin-left: 0.5rem;
}
.particles-modal--content {
  position: relative;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
}
@media (min-width: 1200px) {
  .particles-page__container {
    max-width: 1150px;
  }
}
@media (min-width: 1300px) {
  .particles-page__container {
    max-width: 1250px;
  }
}
@media (min-width: 1400px) {
  .particles-page__container {
    max-width: 1350px;
  }
}
@media (min-width: 1500px) {
  .particles-page__container {
    max-width: 1440px;
  }
}
.particles-modal .particles-page__container {
  max-width: 100%;
}
.particles-navigation-menu--utility-right:last-of-type {
  margin-right: 0;
}
